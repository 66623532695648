<template>
  <div class="base-container">
    <div class="x3 header">
      <div>
        活动：
        <el-select
          v-model="checkActivity"
          placeholder="请选择"
          class="activity-select"
        >
          <el-option
            v-for="(item, i) in activityList"
            :key="i"
            :label="item.activityName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <marketing :checkActivity="checkActivity" />
  </div>
</template>

<script>
import marketing from "@/components/marketing";
import { getActivityList } from "@/api/marketActivity";
export default {
  components: {
    marketing
  },
  data() {
    return {
      checkActivity: "",
      activityList: []
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.getActivityList();
  },
  methods: {
    /**
     * 获取活动
     */
    async getActivityList() {
      this.activityList = (await getActivityList(this.estateId)) || [];
      if (this.activityList.length) {
        this.checkActivity = this.activityList[0].id;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-container {
  background-color: #ffffff;
  .header {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .activity-select {
      width: 300px;
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: none;
            font-size: 16px;
            color: #3470fc;
          }
        }
      }
    }
  }
}
</style>
