var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-container"
  }, [_c('div', {
    staticClass: "x3 header"
  }, [_c('div', [_vm._v(" 活动： "), _c('el-select', {
    staticClass: "activity-select",
    attrs: {
      "placeholder": "请选择"
    },
    model: {
      value: _vm.checkActivity,
      callback: function callback($$v) {
        _vm.checkActivity = $$v;
      },
      expression: "checkActivity"
    }
  }, _vm._l(_vm.activityList, function (item, i) {
    return _c('el-option', {
      key: i,
      attrs: {
        "label": item.activityName,
        "value": item.id
      }
    });
  }), 1)], 1)]), _c('marketing', {
    attrs: {
      "checkActivity": _vm.checkActivity
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }